import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

function AreaServedList() {
  return (
    <StaticQuery
      query={graphql`
        query getFeaturedCities {
          allServiceAreaContent: allMarkdownRemark(
            filter: {
              frontmatter: {
                templateKey: { eq: "service-area" }
                featuredcity: { eq: true }
              }
            }
          ) {
            edges {
              node {
                id
                frontmatter {
                  slug
                  featuredcity
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => { 
        return (
          <div>
            <div>
              <div className="lg:mt-0 mt-6">
                <h4 className="text-sm leading-5 font-bold tracking-wider text-brand-600 uppercase font-display">
                Areas Served
                </h4>
           
                <ul className="mt-2 leading-relaxed p-0 list-none flex flex-wrap">
                {data.allServiceAreaContent && data.allServiceAreaContent.edges && data.allServiceAreaContent.edges.map((city) => {
                    const {title, slug} = city.node.frontmatter
                  return (
                   <li key={city.node.id} className="pb-2 text-base leading-6 text-gray-700 flex items-center flex-grow w-1/2">
                        <Link to={`/${slug}-siding/`} title={`Siding ${title}, CA`}>{title}</Link>
                   </li>
                  );
                })}
                </ul>
                <Link to={'/cities/'} title={'Complete Service Area List'} className="italic text-gray-700">View Full Service Area</Link>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export default AreaServedList;

import React, { useState } from 'react'
import { navigate } from "gatsby"
import {setItemToLStore} from '../helpers/hash'
import InputMask from "react-input-mask";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function ContactForm({ hideLabels, showPlaceholders, stacked, fontsize, footerContained }) {
  const [formInputStates, setFormInputStates] = useState()
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [formSubmitErrors, setFormSubmitErrors] = useState([])


  function handleChange(e) {
    setFormInputStates({ ...formInputStates, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    setSubmitInProgress(true)
    e.preventDefault()
   
    if (!validateForm()) {
      window.scroll(0, 0)
      setSubmitInProgress(false)
      return
    }
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'subject': (formInputStates.Lastname + ", " + formInputStates.Firstname + " (" +  (new Date().toLocaleString()) + ")"),
        'form-name': form.getAttribute('name'),
        ...formInputStates,
        'landingURL': (typeof window !== 'undefined' && window.localStorage && window.localStorage.getItem('lNdUrL') ? window.localStorage.getItem('lNdUrL') : 'Not Provided'),
        'currentPath': (typeof window !== 'undefined' && window.localStorage && window.localStorage.getItem('crNtPaTH') ? window.localStorage.getItem('crNtPaTH') : 'Not Provided')

      }),
    })
      .then(() => {
         setItemToLStore('form', {date: new Date(), ...formInputStates})
        //after submit success, show spinner .05 sec 
        setTimeout(
          () => navigate(form.getAttribute('action')), 
          500
        );

      })
      .catch((error) => {
        alert(error)
        setSubmitInProgress(false)
      })
  }


  function validateForm() {
    let sanitzePhone = formInputStates.phone.replace(/_/g, "")
    
    let formValidateSuccess = true
    let formErrorFirstName = false
    let formErrorLastName = false
    let formErrorEmail = false
    let formErrorDesc = false
    
    let listOfErrors = []

 


    if (
      formInputStates.Firstname.length < 2 ||
      !formInputStates.Firstname.replace(/\s/g, '').length
    ) {
      listOfErrors.push("First name is a required field.")
      formValidateSuccess = false
      formErrorFirstName = true
    }
    if (
      formInputStates.Lastname.length < 2 ||
      !formInputStates.Lastname.replace(/\s/g, '').length
    ) {
      listOfErrors.push("Last name is a required field.")
      formValidateSuccess = false
      formErrorLastName = true
    }

    if (sanitzePhone.length - 4 < 10) {
 
      listOfErrors.push("Please enter a complete phone number.")
      formValidateSuccess = false
      formErrorLastName = true
    }


    


    if (
      !formInputStates.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      listOfErrors.push("Email is a required field.")
      formValidateSuccess = false
      formErrorEmail = true
    } 
    if (
      formInputStates.description.length < 1 ||
      !formInputStates.description.replace(/\s/g, '').length
    ) {
      listOfErrors.push("Description cannot be left blank.")
      formValidateSuccess = false
      formErrorDesc = true
    }
 

    setFormSubmitErrors(listOfErrors)

    return formValidateSuccess
  }


  return (


    <form
      name="contact"
      method="post"
      action="/contact/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >

      
 
      {formSubmitErrors.length > 0 ? 
      <div className="rounded-xl mb-6 bg-white p-4">
        <span class="block text-center mb-2 text-red-600 font-semibold">
        <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
</svg> Oops! There were some problems with your submission.
        </span>
       
<ul className="p-0 m-0 list-none text-center">

        {formSubmitErrors.map((error) => (<li className="block">- {error}</li>))}
        </ul>

      </div>: null} 
     

      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="landingURL" />
      <input type="hidden" name="subject" />
      <input type="hidden" name="currentPath" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <div className="grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-6">

        <div  className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
          
          <label className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'Firstname'}>First Name</label>
          <input
            className="w-full input border-gray-200 p-3 bg-white rounded-xl border appearance-none"
            type={'text'}
            name={'Firstname'}
            onChange={handleChange}
            id={'Firstname'}
            required={true}
            placeholder={showPlaceholders ? 'First Name' : ''}
          />
        </div>

        <div  className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
        <label className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'Lastname'}>Last Name</label>

          <input
            className="w-full input border-gray-200 p-3 bg-white rounded-xl border appearance-none"
            type={'text'}
            name={'Lastname'}
            onChange={handleChange}
            id={'Lastname'}
            required={true}
            placeholder={showPlaceholders ? 'Last Name' : ''}
          />

        </div>
        <div  className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
        <label className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'email'}>Email</label>

          <input
            className="w-full input border-gray-200 p-3 bg-white rounded-xl border appearance-none"
            type={'email'}
            name={'email'}
            onChange={handleChange}
            id={'email'}
            required={true}
            placeholder={showPlaceholders ? 'Email' : ''}
          />

        </div>

        <div className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
        <label className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'phone'}>Phone</label>
          {/* <input
            className="w-full input border-gray-200 p-3 bg-white rounded-xl border appearance-none"
            type={'phone'}
            name={'phone'}
            onChange={handleChange}
            id={'phone'}
            required={true}
            placeholder={showPlaceholders ? 'Phone' : ''} 
          /> */}

<InputMask placeholder={showPlaceholders ? 'Phone' : ''} 
className="w-full input border-gray-200 p-3 bg-white rounded-xl border appearance-none" 
mask="(999) 999-9999"  
type="tel"
required={true}
name={'phone'} id={'phone'} onChange={handleChange} value={formInputStates && formInputStates.phone ? formInputStates.phone : null} />

        </div>
        <div className="sm:col-span-6">
           <label className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'description'}>Tell us about your project</label>

          <textarea
            className="textarea w-full input border-gray-200 p-3 bg-white rounded-xl border appearance-none"
            name={'description'}
            rows={5}
            onChange={handleChange}
            id={'description'}
            required={true}
            placeholder={showPlaceholders ? 'Tell us about your project' : ''}
          />

        </div>
      </div>
      <div className="field">
  <button className="button-active-bhvr w-full mt-2 md:text-lg cursor-pointer transition duration-150 ease-in-out bg-brand-500 focus:bg-brand-600 hover:bg-brand-600 focus:outline-none font-semibold px-4 py-3 lg:py-3 lg:px-5 rounded-full text-white font-display flex items-center justify-center" type="submit">Request FREE Estimate {submitInProgress ? <svg class="animate-spin ml-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg> : null} </button> 
      </div>
      
<div className={`mt-3`}>

<p className="text-gray-500 text-center text-sm flex items-center justify-center"> <svg className="w-4 h-4 mr-1 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
</svg> Your privacy is important to us.</p>
</div>
     
    </form>

  )
}

export default ContactForm

import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import '../styles/tailwind.css'
import '../styles/custom.css' 

const TemplateWrapper = ({ children, headerTransparent, className, bottomBorder, isHomePage = false  }) => { 
  const { companyname } = useSiteMetadata()

  return (
    <div className={`${className ? className : '' } template-wrapper`}>
      <Navbar headerTransparent={headerTransparent} bottomBorder={bottomBorder}/>
      <div>{children}</div>
      <Footer isHomePage={isHomePage}/>
      {/* <div className="background-overlay pointer-events-none"/> */}
    </div>
  )
}

export default TemplateWrapper
